import React from "react"

import socialImage from "../../static/DECAZES-social-share.jpg"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Home from "../components/Home"

const HomePage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Bienvenue sur le site de la famille Decazes"
        description="Bienvenue sur le site de la famille Decazes. Vous y retrouverez un historique sur le Duc Decazes, un arbre généalogique ainsi qu'une liste de liens concernant des activités des membres de la famille Decazes."
        image={socialImage}
      />
      <Home data={data} />
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query getHomeData {
    file(relativePath: { eq: "elie-decazes.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
