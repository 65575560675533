import React from "react"
import { useSpring, animated } from "react-spring"
import Img from "gatsby-image"

import "./styles.scss"

const Home = ({ data }) => {
  const imageprops = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(20%)" },
    config: { mass: 1, tension: 240, friction: 38, precision: 0.00001 },
    delay: 100,
  })
  const textprops = useSpring({
    to: { opacity: 1, transform: "translateY(0)" },
    from: { opacity: 0, transform: "translateY(20%)" },
    config: { mass: 1, tension: 240, friction: 38, precision: 0.00001 },
    delay: 280,
  })
  return (
    <div className="home">
      <div className="home-intro">
        <animated.div style={imageprops} className="home-intro-image">
          <Img
            loading="auto"
            fluid={data.file.childImageSharp.fluid}
            alt="Elie Decazes"
          />
        </animated.div>
        <animated.div style={textprops} className="home-intro-text">
          <h1 className="home-intro-text-title">DECAZES</h1>
          <h2 className="home-intro-text-subtitle">
            Bienvenue sur le site de la famille Decazes
          </h2>
        </animated.div>
      </div>
    </div>
  )
}
export default Home
